/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  AppsflyerAttributionData,
  Appsflyer,
  AppsflyerSdkOptions,
  AppsflyerSuccessResponse,
  AppsflyerUnifiedDeepLinkResponse,
  UnifiedData,
  CordovaIonicPlugin,
  DeeplinkData
} from './types';

export interface AppsflyerPlugin {
  /**
   * It will keep track of open app attribution data.
   *
   * Note, this property should only be accessed after
   * initSdk method have resolved completely
   */
  openAppAttributionData: AppsflyerAttributionData | UnifiedData | null;

  /**
   * It will keep track of install app attribution data.
   *
   * Note, this property should only be accessed after
   * initSdk method have resolved completely
   */
  installAppAttributionData: AppsflyerAttributionData | null;
  /**
   * It does appsflyer sdk initialization.
   * As part of initialization it also sets attribution data in property
   * installAppAttributionData.
   */
  initSdk(timeout: number): Promise<void>;

  /**
   * I attach registerOnAppOpenAttribution event and listen for
   * deep link data.
   *
   * I will set openAppAttributionData
   */
  handleOnAppOpenAttributionEvent(
    onSuccess: (attributionData: AppsflyerAttributionData) => void,
    onFailure: (error: object) => void
  ): void;

  /**
   * Tracks the specified event in Appsflyer
   */
  trackInAppEvent(eventName: string, eventValues: object): Promise<void>;

  /**
   * Sets the UserID from the referral code
   */
  setUserId(referralCode: string): void;

  /**
   *  Get unified deep link data
   */
  unifiedDeepLinkEvent(showResponse: (response: any) => void): void;

  openUrlHandler(url: string): void;
}

export class AppsflyerCordovaPlugin implements AppsflyerPlugin {
  private _openAttributionData:
    | AppsflyerAttributionData
    | UnifiedData
    | null = null;
  private _installAttributionData: AppsflyerAttributionData | null = null;

  get openAppAttributionData() {
    return this._openAttributionData;
  }

  get installAppAttributionData() {
    return this._installAttributionData;
  }
  constructor(
    private appsflyer: Appsflyer,
    private ionicPlugin: CordovaIonicPlugin,
    private devKey: string,
    private appId: string
  ) {}

  initSdk(timeout: number): Promise<void> {
    const initAF = (): Promise<void> => {
      return new Promise((resolve, reject) => {
        const sdkOptions: AppsflyerSdkOptions = {
          isDebug: true,
          devKey: this.devKey,
          appId: this.appId,
          onInstallConversionDataListener: true,
          onDeepLinkListener: true
        };
        const onSuccess = (json: string) => {
          const result: AppsflyerSuccessResponse = JSON.parse(json);
          this._installAttributionData = result.data;
          resolve();
        };

        const onFailure = (json: string) => {
          reject(new Error(json));
        };
        this.appsflyer.initSdk(sdkOptions, onSuccess, onFailure);
      });
    };

    const createTimeout = (timeout: number): Promise<void> => {
      return new Promise((resolve) => {
        setTimeout(resolve, timeout);
      });
    };

    return Promise.race([
      initAF(),
      createTimeout(timeout).then(() => {
        throw new Error(`Timeout for Appsflyer initSdk after ${timeout}ms`);
      })
    ]);
  }

  handleOnAppOpenAttributionEvent(
    onSuccess: (data: AppsflyerAttributionData) => void,
    onFailure: (error: object) => void
  ): void {
    const onOpenSuccess = (json: string) => {
      const result: AppsflyerSuccessResponse = JSON.parse(json);
      this._openAttributionData = result.data;
      onSuccess(result.data);
    };

    const onOpenFailure = (json: string) => {
      const error = JSON.parse(json);
      onFailure(error);
    };
    this.appsflyer.registerOnAppOpenAttribution(onOpenSuccess, onOpenFailure);
  }

  unifiedDeepLinkEvent(callback: (response: UnifiedData) => void): void {
    this.ionicPlugin.onDeepLink((data: DeeplinkData) => {
      this.appsflyer.handleOpenUrl(data.url);
    });
    const onOpenSuccess = (json: string) => {
      const result: AppsflyerUnifiedDeepLinkResponse = JSON.parse(json);
      this._openAttributionData = result.data;
      callback(result.data);
    };
    this.appsflyer.registerDeepLink(onOpenSuccess);
  }

  trackInAppEvent(
    eventName: string,
    eventValues: { [s: string]: string }
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      this.appsflyer.logEvent(eventName, eventValues, resolve, reject);
    });
  }

  setUserId(referralCode: string): void {
    this.appsflyer.setAppUserId(referralCode);
  }

  openUrlHandler(url: string): void {
    this.appsflyer.handleOpenUrl(url);
  }
}

export class AppsflyerWebPlugin implements AppsflyerPlugin {
  private _openAttributionData: AppsflyerAttributionData | null = null;
  private _installAttributionData: AppsflyerAttributionData | null = null;

  get openAppAttributionData() {
    return this._openAttributionData;
  }

  get installAppAttributionData() {
    return this._installAttributionData;
  }
  constructor(
    private sendAnalytics: (data: { event: string; payload: any }) => void
  ) {}

  initSdk(): Promise<void> {
    return Promise.resolve();
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  handleOnAppOpenAttributionEvent(): void {}

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  unifiedDeepLinkEvent(): void {}

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  openUrlHandler(): void {}

  trackInAppEvent(
    eventName: string,
    eventValues: { [s: string]: string }
  ): Promise<void> {
    this.sendAnalytics({
      event: 'afCustom',
      payload: {
        event: 'nsAppsFlyer',
        category: 'OAC',
        action: eventName,
        label: '',
        value: eventValues
      }
    });
    return Promise.resolve();
  }

  setUserId(referralCode: string): void {
    this.sendAnalytics({
      event: 'afRefferalCode',
      payload: {
        referralCode
      }
    });
  }
}

export class AppsflyerNoopPlugin implements AppsflyerPlugin {
  private _openAttributionData: AppsflyerAttributionData | null = null;
  private _installAttributionData: AppsflyerAttributionData | null = null;

  get openAppAttributionData() {
    return this._openAttributionData;
  }

  get installAppAttributionData() {
    return this._installAttributionData;
  }

  initSdk(): Promise<void> {
    return Promise.resolve();
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  handleOnAppOpenAttributionEvent(): void {}

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  unifiedDeepLinkEvent(): void {}

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  openUrlHandler(): void {}

  trackInAppEvent(
    eventName: string,
    eventValues: { [s: string]: string }
  ): Promise<void> {
    return Promise.resolve();
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setUserId(referralCode: string): void {}
}
